// AT-2102 | AT-2161 | Ankur Makavana | 18-07-2023 | Reports to have to 2 sub-sections: 1. Mobile Reports Desktop 2. MIS Reports Reports Master - to be greyed out for now Finance Reports - to be greyed out for now Business Reports
import React from "react";
import customFetch from "./apicall/api";
import { logout, consolidate, filterData } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import {
  FileExcelOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import home_log from "../assets/images/home_logo.png";
const e_designation_id = localStorage.getItem("e_designation_id");
class Report extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      report_categories: [],
      isSpin: false,
      flag: 0,
    };
  }
  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 });
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        if (
          tokenData.userid != "" &&
          tokenData.access_token != "" &&
          tokenData.effective_desgn_id != ""
        ) {
          localStorage.setItem("in_userid", tokenData.userid);
          localStorage.setItem("api_token", tokenData.access_token);
          localStorage.setItem("designation_name", tokenData.designation_name);
          localStorage.setItem("employee_id", tokenData.employeeid);
          localStorage.setItem("em_branch_id", tokenData.em_branch_id);
          localStorage.setItem(
            "e_designation_id",
            tokenData.effective_desgn_id
          );
          localStorage.setItem("em_branch_id", tokenData.em_branch_id);
          localStorage.setItem("employee_id", tokenData.employeeid);
          // this.props.history.push({
          //   pathname: "/Report",
          //   state: {
          //     api_token: tokenData.access_token,
          //     effective_designation_id: tokenData.effective_desgn_id,
          //   },
          // });
        } else {
          const { api_token } =
            (this.props.location && this.props.location.state) || {};
          localStorage.setItem("api_token", api_token);
        }
        console.log("AndroidInterface->", data);
      }
    } else {
      console.error(
        "Error in AndroidInterface: Android method not found or not available."
      );
      // Handle the error gracefully
    }

    this.setState({
      isSpin: true,
    });

    customFetch(
      process.env.REACT_APP_URL + "/get/list/categories/subcategories",
      {
        method: "GET",
        headers: { "api-token": localStorage.getItem("api_token") },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        this.setState({
          report_categories: data,
          isSpin: false,
        });
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
    console.log(process.env.REACT_APP_MOB_REP);
  }

  render() {
    // const { api_token, effective_designation_id } =
    //   (this.props.location && this.props.location.state) || {};
    const effective_designation_id = parseInt(
      localStorage.getItem("e_designation_id")
    );
    const api_token = localStorage.getItem("api_token");
    const { report_categories } = this.state;
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div className="container no-container ">
        {this.state.flag === 0 && (
          <div className="top_nav_report">
            <a>
              {/*AT-2335 ||Rohit Chitampalle ||23-08-2023 ||added icon and redirect to dashboard*/}
              <div
                className="icon_bg-report"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/dashboard",
                    state: {
                      api_token: api_token,
                    },
                  });
                }}
              >
                {/* AT-2335 ||Rohit Chitampalle ||23-08-2023 ||change in icon */}
                <img
                  src={require("../assets/images/home_white_logo.png")}
                  className="icon_img"
                />
              </div>
            </a>
            <span className="head-title">Reports</span>

            <a>
              <div className="log-out-reporthead">
                <img
                  src={require("../assets/images/power-back.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </a>
          </div>
          )}
          <div className="dashboard-card-holder row mt-4">
            {report_categories.categories &&
              report_categories.categories.map((categories, index) => (
                <>
                  {categories.report_category_id == 1 ? (
                    <>
                      <div className="image-report-2">
                        <div
                          className={
                            process.env.REACT_APP_MOB_REP.split(",").includes(
                              effective_designation_id.toString()
                            ) == false || categories.status != "A"
                              ? "not-allowed m-2"
                              : "m-2"
                          }
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/Report_categories",
                              state: {
                                api_token: api_token,
                                sub_categories:
                                  report_categories.sub_categories,
                                categories_id: categories.report_category_id,
                                categories_name: categories.category_name,
                                effective_designation_id: effective_designation_id,
                              },
                            });
                          }}
                        >
                          {categories.ui_img_path ? (
                            <img
                              src={require("../assets/images/" +
                                categories.ui_img_path)}
                              alt=""
                            />
                          ) : (
                            <img
                              src={require("../assets/images/check-list.png")}
                              alt=""
                            />
                          )}
                          <h5>{categories.category_name}</h5>
                        </div>
                      </div>
                    </>
                  ) : categories.report_category_id == 3 ? (
                    <>
                      <div className="image-report-2">
                        <div
                          className={
                            process.env.REACT_APP_PBI_REP.split(",").includes(
                              effective_designation_id.toString()
                            ) == false || categories.status != "A"
                              ? "not-allowed m-2"
                              : "m-2"
                          }
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/Report_categories",
                              state: {
                                api_token: api_token,
                                sub_categories:
                                  report_categories.sub_categories,
                                categories_id: categories.report_category_id,
                                categories_name: categories.category_name,
                                effective_designation_id: effective_designation_id,
                              },
                            });
                          }}
                        >
                          {categories.ui_img_path ? (
                            <img
                              src={require("../assets/images/" +
                                categories.ui_img_path)}
                              alt=""
                            />
                          ) : (
                            <img
                              src={require("../assets/images/check-list.png")}
                              alt=""
                            />
                          )}
                          <h5>{categories.category_name}</h5>
                        </div>
                      </div>
                    </>
                  ) : categories.report_category_id == 4 ? (
                    <div className="image-report-2">
                      <div
                        className={
                          (categories.status !== "A" ||
                            (process.env.REACT_APP_SUPERSET_BRANCH_ACCESS &&
                            !process.env.REACT_APP_SUPERSET_BRANCH_ACCESS.split(",")
                              .includes(localStorage.getItem("branch_id").toString()))
                          ) ? "not-allowed m-2" : "m-2"
                        }
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/Report_categories",
                            state: {
                              api_token: api_token,
                              sub_categories: report_categories.sub_categories,
                              categories_id: categories.report_category_id,
                              categories_name: categories.category_name,
                              effective_designation_id: effective_designation_id,
                            },
                          });
                        }}
                      >
                        {categories.ui_img_path ? (
                          <img
                            src={require("../assets/images/" +
                              categories.ui_img_path)}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../assets/images/check-list.png")}
                            alt=""
                          />
                        )}
                        <h5>{categories.category_name}</h5>
                      </div>
                    </div>
                  ): (
                    <div className="image-report-2">
                      <div
                        className={
                          categories.status != "A" ? "not-allowed m-2" : "m-2"
                        }
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/Report_categories",
                            state: {
                              api_token: api_token,
                              sub_categories: report_categories.sub_categories,
                              categories_id: categories.report_category_id,
                              categories_name: categories.category_name,
                              effective_designation_id: effective_designation_id,
                            },
                          });
                        }}
                      >
                        {categories.ui_img_path ? (
                          <img
                            src={require("../assets/images/" +
                              categories.ui_img_path)}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../assets/images/check-list.png")}
                            alt=""
                          />
                        )}
                        <h5>{categories.category_name}</h5>
                      </div>
                    </div>
                  )}
                </>
              ))}
          </div>
        </div>
      </>
    );
  }
}

export default Report;
